/*
  Finds animatable SVG elements within an element with class "animate-svg",
  and triggers animation on hover/focus.

  Requirements:
  - The hover/focus target should have class "animate-svg"
  - It needs to contain at least one SVG element, which in turn has a
    nested element (path, etc) with class "svg-animated-element"
  - The SVG itself should contain CSS animations using keyframes
    - Note: It's a good idea to set a limited amount of iterations on the animations
*/

document.querySelectorAll(".animate-svg").forEach(element => {
  let animations: Animation[] = [];

  element.querySelectorAll(".svg-animated-element").forEach(animatedNode => {
    animations = [...animations, ...animatedNode.getAnimations()];
  });

  // Make sure all animations reset after playing
  for (const animation of animations) {
    animation.addEventListener("finish", () => animation.cancel());
  }

  const triggerAnimation = (e: Event) => {
    animations.forEach(
      animation => animation.playState !== "running" && animation.play()
    );
  };

  element.addEventListener("mouseenter", triggerAnimation);
  element.addEventListener("focus", triggerAnimation);
});
